$body-color: #000;

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

body {
  color: $body-color;
  font-family: sans-serif;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  cursor: none;
}
